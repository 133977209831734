import '@fancyapps/fancybox/dist/jquery.fancybox.min'
import {WOW} from 'wowjs/dist/wow.js'

var wow = new WOW({
  mobile: false,
});
wow.init();
// new WOW().init();
//header搜索
$('.header-search .header-icon').on('click',function(){
  $('.header-search').addClass('show-form')
})
$('body').on('click',function(e){
  if($(e.target).parents('.header-search').length == 0){
    $('.header-search').removeClass('show-form')
  }
})

//移动端导航
$('[data-open="cmenu"]').on('click', function () {
  if ($('header .hamburger').hasClass('active')) {
    $('header .hamburger').removeClass('active')
    $('header .mob-nav').removeClass('show')
    $('header').removeClass('show-mob-nav')
  } else {
    $('header .hamburger').addClass('active')
    $('header .mob-nav').addClass('show')
    $('header').addClass('show-mob-nav')
  }
})
$('.mob-nav ul li .title .iconfont').on('click',function(){
  var $parentsEl = $(this).parents('li')
  if($parentsEl.hasClass('show-sub')){
    $parentsEl.removeClass('show-sub').find('.sub-nav').slideUp(200)
  }else{
    $parentsEl.siblings().removeClass('show-sub').find('.sub-nav').slideUp(200)
    $parentsEl.addClass('show-sub').find('.sub-nav').slideDown(200)
  }
})


//页面滚动监听
$(document).scroll(function () {
  var scroH = $(document).scrollTop(); //滚动高度
  var viewH = $(window).height(); //可见高度
  var contentH = $(document).height(); //内容高度

  if($('.header-search').hasClass('show-form')){
    $('.header-search').removeClass('show-form')
  }

  if(scroH > 170){
    $('header').addClass('scroll')
  }else{
    $('header').removeClass('scroll')
  }
  
});

//返回顶部
$('.footer .back-top').on('click',function(){
  $("html, body").animate({
    scrollTop: 0
  }, {
      duration: 500,
      easing: "swing"
  });
  return false;
})
