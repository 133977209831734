module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


if (!bannerPath) var bannerPath='';
if (!title) var title = '';
if (!entitle) var entitle = '';
if (!crumbs) var crumbs = '';
;
__p += '\r\n<section class="ny-banner">\r\n  <div class="bg-img" style="background-image: url(' +
((__t = ( bannerPath )) == null ? '' : __t) +
');"></div>\r\n  <div class="banner-title">\r\n    <div class="container">\r\n      <h1 class="wow slideInUp">' +
((__t = ( title )) == null ? '' : __t) +
'</h1>\r\n      <h2 class="wow slideInUp" data-wow-delay="0.3s">' +
((__t = ( entitle )) == null ? '' : __t) +
'</h2>\r\n    </div>\r\n  </div>\r\n  <div class="crumbs">\r\n    <div class="container wow slideInUp" data-wow-delay="0.5s">\r\n      <a href="#"><i class="iconfont icon-shouye-"></i></a>\r\n      <span><i class="iconfont icon-you"></i></span>\r\n      ' +
((__t = ( crumbs )) == null ? '' : __t) +
'\r\n      <!-- <a href="#">媒体中心</a>\r\n      <span><i class="iconfont icon-you"></i></span>\r\n      <a href="#">影像资料</a> -->\r\n    </div>\r\n  </div>\r\n</section>';

}
return __p
}